import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "../../../components/Layout/Layout"
import MoreTopics from "../../../components/Education/MoreTopics"
import styled from "styled-components";
import HeadingSpecial from "../../../components/shared/HeadingSpecial";
import {Row, Col, Container} from "reactstrap";
import {graphql, StaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import SnowFlakes from "../../../components/shared/SnowFlakes";

const pageSlug = "characters"
const pageTitle = "Characters"
const categorySlug = "adaptation"
const categoryTitle = "Adaptation"

const Content = styled.div`
    position: relative;
    
    text-align: center;
    
    h3 {
        font-size: 2rem;
        line-height: 2rem;
        margin: 0.25rem auto;
    }
    
    h4 {
      max-width: 740px;
    }
    
    .inner {
        max-width: 700px;
        margin: 0 auto;
    }
    .link {
      color: #5fc5eb;
    }
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                anna: file(relativePath: { eq: "Characters/adapt-characters-anna.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 700, quality: 100)
                    }
                }
                elsa: file(relativePath: { eq: "Characters/adapt-characters-elsa.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 700, quality: 100)
                    }
                }
                hans: file(relativePath: { eq: "Characters/adapt-characters-hans.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 700, quality: 100)
                    }
                }
                kingqueen: file(relativePath: { eq: "Characters/adapt-characters-kingqueen.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 700, quality: 100)
                    }
                }
                kristoff: file(relativePath: { eq: "Characters/adapt-characters-kristoff.jpg" }) {
                    childImageSharp {
                       gatsbyImageData(width: 700, quality: 100)
                    }
                }
                olaf: file(relativePath: { eq: "Characters/adapt-characters-olaf.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 700, quality: 100)
                    }
                }
                pabbie: file(relativePath: { eq: "Characters/adapt-characters-pabbie.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 700, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Characters data={data}/>
        )}
    />
)

class Characters extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: pageSlug,
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="beyond-the-story"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                <Content className="enrichment">
                    <HeadingSpecial lines={false} headingTag="h1"
                                    className="py-4 py-md-5 font--bigger"
                                    title={pageTitle}/>
                    <Container fluid={true}>
                            <p className="font--regular font-weight-bold mx-auto narrow">Teachers, take your experience further. <a
                                href="/pdf/FROZEN_LessonPlan_FrozenPictures.pdf" target="_blank"
                                className="link no-interstitial-check">Download&nbsp;this&nbsp;lesson&nbsp;plan</a> exploring character and point of view. </p>
                            <Row className="my-lg-5">
                                <Col xs={12} lg={6} className="mb-5 my-lg-auto">
                                    <div className="inner">
                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="font--bigger"
                                                    title="Elsa"/>
                                    <GatsbyImage placeholder="none"
                                        image={getImage(this.props.data.elsa)}
                                        alt=""
                                        title=""
                                        className="image d-block mb-4"
                                    />

                                    <p>Elder princess of Arendelle who possesses a powerful magic but must conceal it and grow up in fear and isolation. When she comes of age and is crowned Queen of Arendelle, a moment of panic unleashes her magic and forces her to flee to the Northern Mountain.</p>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6} className="mb-5 my-lg-auto">
                                    <div className="inner">
                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="font--bigger"
                                                    title="Anna"/>
                                    <GatsbyImage placeholder="none"
                                        image={getImage(this.props.data.anna)}
                                        fadeIn={true}
                                        alt=""
                                        title=""
                                        className="image d-block mb-4"
                                    />

                                    <p>Younger princess of Arendelle who is full of life and fun but grows up alone, longing for contact with the outside world and with her sister, whom she desperately loves but hardly knows.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="my-lg-5">
                                <Col xs={12} lg={6} className="mb-5 my-lg-auto">
                                    <div className="inner">
                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="font--bigger"
                                                    title="Queen Iduna & King Agnarr"/>
                                    <GatsbyImage placeholder="none"
                                        image={getImage(this.props.data.kingqueen)}
                                        alt=""
                                        title=""
                                        className="image d-block mb-4"
                                    />

                                    <p>Monarchs of Arendelle and parents of Elsa and Anna. King Agnarr attempts to protect the family by encouraging Elsa to conceal her magic and her feelings. Queen Iduna, daughter of the Northern Nomads, laments her children growing up apart from one another.</p>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6} className="mb-5 my-lg-auto">
                                    <div className="inner">
                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="font--bigger"
                                                    title="Pabbie & Bulba"/>
                                    <GatsbyImage placeholder="none"
                                        image={getImage(this.props.data.pabbie)}
                                        fadeIn={true}
                                        alt=""
                                        title=""
                                        className="image d-block mb-4"
                                    />

                                    <p>The wise and protective leaders of the Hidden Folk.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="my-lg-5">
                                <Col xs={12} lg={6} className="mb-5 my-lg-auto">
                                    <div className="inner">
                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="font--bigger"
                                                    title="Kristoff & Sven"/>
                                    <GatsbyImage placeholder="none"
                                        image={getImage(this.props.data.kristoff)}
                                        alt=""
                                        title=""
                                        className="image d-block mb-4"
                                    />

                                    <p>Kristoff, the stubborn and independent mountain man who was raised by Hidden Folk. His best friend is a reindeer named Sven.</p>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6} className="mb-5 my-lg-auto">
                                    <div className="inner">
                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="font--bigger"
                                                    title="Olaf"/>
                                    <GatsbyImage placeholder="none"
                                        image={getImage(this.props.data.olaf)}
                                        fadeIn={true}
                                        alt=""
                                        title=""
                                        className="image d-block mb-4"
                                    />

                                    <p>The lovable snowman brought to life by Elsa's magic who joins Anna, Kristoff, and Sven on their journey.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="my-lg-5">
                                <Col xs={12} lg={6} className="mb-5 my-lg-auto">
                                    <div className="inner">
                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="font--bigger"
                                                    title="Hans"/>
                                    <GatsbyImage placeholder="none"
                                        image={getImage(this.props.data.hans)}
                                        alt=""
                                        title=""
                                        className="image d-block mb-4"
                                    />

                                    <p>The charming thirteenth son of the King of the Southern Isles who proposes to Princess Anna upon meeting her at Queen Elsa’s coronation.</p>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6} className="mb-5 my-lg-auto">
                                    <div className="inner">
                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="font--bigger"
                                                    title="Duke of Weselton"/>
                                    <p>The pompous representative of Arendelle’s most profitable trading partner who is overly suspicious of powerful women.</p>

                                    <HeadingSpecial lines={false} headingTag="h3"
                                                    className="mt-5 font--bigger"
                                                    title="Oaken"/>
                                    <p>The friendly yet shrewd proprietor of Wandering Oaken’s Trading Post and Sauna.</p>
                                    </div>
                                </Col>
                            </Row>
                    </Container>
                </Content>
                    <div className="py-5">&nbsp;</div>
                </SnowFlakes>
                <MoreTopics inspiration design/>
            </Layout>
        )
    }
}
export default Query